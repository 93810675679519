import React, { useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Contact.css';
import Footer from './Footer';

function Contact() {
  const form = useRef();
  const [phone, setPhone] = useState('');

  const clearForm = () => {
    form.current.reset(); // Reset all form fields
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.current.submit();
    alert('We’ve successfully received your message. Our team will get in touch with you shortly.');
    clearForm();
  };

  return (
    <div>
      <div className='about-header'></div>
      <center><h1 className='about-header-content'>Contact Us</h1></center>
      <div className='form-box-1'>
        <h4>We would love to hear from you</h4>

        <form 
          ref={form} 
          action="https://formspree.io/f/mwpkpznd" 
          method="POST" 
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" name="user_name" required />
          </div>

          <div className="form-group">
            <label>Company Name</label>
            <input type="text" className="form-control" name="company_name" />
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            <PhoneInput
              country={'us'}
              inputClass="form-input"
              required
              value={phone}
              onChange={(value) => setPhone(value)}
              name="phone"
            />
            <input type="hidden" name="phone" value={phone} />
          </div>

          <div className="form-group">
            <label>Email Address</label>
            <input type="email" className="form-control" name="email" required />
          </div>

          <div className="form-group">
            <label>Address</label>
            <input type="text" className="form-control" name="address" required />
          </div>

          <div className="form-group">
            <label>Subject</label>
            <input type="text" className="form-control" name="subject" />
          </div>

          <div className="form-group">
            <label>Message</label>
            <textarea className="form-control" name="message" cols="5" rows="5" required></textarea>
          </div>

          <div className="form-group">
            <label>Select Country:</label>
            <div className='country-radio'>
              <select name="country" required>
                <option value="">Select your Country</option>
                <option value="India">India</option>
                <option value="China">China</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Enter Your State or Province</label>
            <input type="text" className="form-control" name="state" required />
          </div>

          <div className="form-group">
            <label>Enter Your City</label>
            <input type="text" className="form-control" name="city" required />
          </div>

          <div className="form-group">
            <label>Enter Zip Code</label>
            <input type="text" className="form-control" name="zip_code" required />
          </div>

          <div className='btns'>
            <button className="btn btn-primary my-2" type="submit">Submit</button>
            <button className="btn btn-secondary my-2" type="button" onClick={clearForm}>Clear</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
