import React, { useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './MemberModal.css';

const MemberModal = ({ onClose }) => {
  const form = useRef();
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const clearForm = () => {
    form.current.reset();
    setCountry(''); // Reset country state
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.current.submit();
    setFormSubmitted(true);
    alert('Thank you for joining China India Business Bridge (CIBB)! We’ve successfully received your membership form.');
    clearForm();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>X</button>
        <center><h2>Become a Member</h2></center>

        {formSubmitted ? (
          <h3>Thank you for joining China India Business Bridge (CIBB)! We’ve successfully received your membership form.</h3>
        ) : (
          <form 
            ref={form} 
            action="https://formspree.io/f/mwpkpznd" 
            method="POST" 
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <label>First Name</label>
              <input type="text" className="form-control" name="first_name" required />
              
              <label>Last Name</label>
              <input type="text" className="form-control" name="last_name" required />

              <div className='phone-card'>
                <label className='pad'>Phone Number</label>
                <PhoneInput 
                  value={phone}
                  onChange={(value) => setPhone(value)} 
                  country={'in'} 
                  inputClass="form-control" 
                  className='form-control1' 
                  name="phone" 
                  required 
                />
                <input type="hidden" name="phone" value={phone} />
              </div>  
              
              <label>Email Address</label>
              <input type="email" className="form-control" name="email" required />
              
              <label>Address</label>
              <input type="text" className="form-control" name="address" required />

              <label>Select Country</label>
              <select className="form-control" name="country" onChange={handleCountryChange} required>
                <option value="">Select your Country</option>
                <option value="India">India</option>
                <option value="China">China</option>
              </select>

              <label>Select Service</label>
              <select className="form-control" name="services" required>
                {country === 'India' && (
                  <>
                    <option value="Select the service you want ">Select the service you want </option>
                    <option value="Product Ideas & Sourcing">Product Ideas & Sourcing</option>
                    <option value="Group Buying">Group Buying</option>
                    <option value="Company & Factory Setup">Company & Factory Setup</option>
                    <option value="Quality Inspection">Quality Inspection</option>
                    <option value="Logistics">Logistics</option>
                    <option value="Entrepreneur Networking">Entrepreneur Networking</option>
                    <option value="Exhibition & Factory Visits">Exhibition & Factory Visits</option>
                  </>
                )}
                {country === 'China' && (
                  <>
                    <option value="Service 1">Select the service you want </option>
                    <option value="Buyer & Company Connections">Buyer & Company Connections</option>
                    <option value="Buyer & Company Verification">Buyer & Company Verification</option>
                    <option value="B2B Marketing Services">B2B Marketing Services</option>
                    <option value="Group Buying">Group Buying</option>
                    <option value="Entrepreneur Networking">Entrepreneur Networking</option>
                    <option value="Exhibition Arrangements">Exhibition Arrangements</option>
                    <option value="Personal Branding">Personal Branding</option>
                  </>
                )}
              </select>

              <label>State/Province</label>
              <input type="text" className="form-control" name="state" required />

              <label>City</label>
              <input type="text" className="form-control" name="city" required />

              <label>Zip Code</label>
              <input type="text" className="form-control" name="zip_code" required />

              <label>Company Name</label>
              <input type="text" className="form-control" name="company_name" required />

              <label>Designation</label>
              <input type="text" className="form-control" name="designation" required />

              <label>LinkedIn URL</label>
              <input type="text" className="form-control" name="linkedin_url" />

              <label>Company Website URL</label>
              <input type="text" className="form-control" name="company_website" />

              <label>Subject</label>
              <input type="text" className="form-control" name="subject" required />

              <label>Message</label>
              <textarea className="form-control" name="message" cols="5" rows="5" required></textarea>

              <input type="checkbox" name="declaration" required /> 
              <p className='p'>I agree to the terms and conditions</p>

              <button type="submit" className="submit-btn">Submit</button>
              <button type="button" className="clear-btn" onClick={clearForm}>Clear</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default MemberModal;
