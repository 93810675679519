import React, { useEffect } from 'react';
import './Service.css'; // Add custom styles in this CSS file
import service_img from './images/service-img.jpg';
import connection1 from './images/buyer connection.png'
import verification from './images/verification.png';
import marketing from './images/marketing.png';
import group from './images/group.png';
import entrapenuer from './images/entrapenuer.png';
import exhibition from './images/exhibition.png';
import buying from './images/buying.png';
import idea from './images/idea.png';
import factory from './images/factory.png';
import quality from './images/quality.png';
import logistic from './images/logistics.png';
import network from './images/network.png';
import office from './images/office.png';
import personal from './images/personal.png';
import service_img1 from './images/service-bg.png';
import service_img_1 from './images/service-img-2.jpg';
import china from './images/service-image-con.png';
import india from './images/service-image-con-2.png';
 import connection from './images/connection.jpg';
import china_india from './images/chin-india-border.png'
 import connection_2 from './images/connection-2.jpg';
 import connection_3 from './images/connection-3.jpg'
const Service = () => {
 
  return (
<div>
<div className='about-header'>

</div>
<center><h1 className='about-header-content'>Our Services</h1></center> 
      <div className='serv-content-img service-2-heading'>
              <img src={service_img1} className='serv-img1'/>
             
        
          </div>

      

  {/* <div className='first-flex'><div className='service-first-content'><h1 className='service-heading'>Buyer & Company Connections</h1>
  <p className='service-content'>Finding the right business partner is crucial for success, and at CIBB, we specialize in connecting Chinese manufacturers with Indian buyers and vice versa. Our personalized matchmaking service ensures that you find reliable, long-term business partners who meet your specific needs.

Access a vast network of verified businesses.
Tailored introductions based on your industry and product.
Seamless communication and negotiation support.</p><button className='service-button'>Contact Us</button></div><img src={service_img
  } alt="cibb service" className='service-heading-image'/>

  </div><div className='first-flex'><img src={service_img_1
  } alt="cibb service" className='service-heading-image'/><div className='service-first-content'><h1 className='service-heading'>Payment Guarantee</h1>
  <p className='service-content'>International business can be risky, but not with CIBB. We offer secure payment guarantee services to protect both Chinese manufacturers and Indian buyers, ensuring every transaction is smooth, safe, and transparent.

Escrow services to safeguard payments.
Secure transaction tracking with real-time updates.
Dispute resolution to ensure peace of mind for all parties.</p><button className='service-button'>Contact Us</button></div>

  </div> */}
  <div className='india-china'><div className='service-2-heading'><div><h1 className='india'>
    Where China’s Innovation Meets India’s Potential</h1>
    </div></div><div className='service-2-heading'>
      <div>
    <img src={india} alt="cibb china logo" className="china-logo"></img>
    <h1 className='service-1-heading'>For Chinese from India
    </h1></div></div>
  <div className='service-content-3'>
    

  <div className='flex-items'> <img src={connection1} alt="cibb connection" className='connection'/> <h1 className='service-third-heading'>
    Buyer & Company Connections:</h1>
<p className='service-third-content'> Match with verified buyers and companies.
Payment Guarantee: Secure transactions with trusted protections.</p>
</div><div className='flex-items'> <img src={verification} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Buyer & Company Verification:</h1> 
<p className='service-third-content'>Vet buyers and companies for reliability.</p></div>
<div className='flex-items'> <img src={marketing} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>B2B Marketing Services: </h1><p className='service-third-content'>Expand your reach with targeted marketing.
</p></div>
<div className='flex-items'> <img src={group} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Group Buying:</h1> 
<p className='service-third-content'>Benefit from bulk purchasing power.</p></div>
<div className='flex-items'> <img src={entrapenuer
} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Entrepreneur Networking:
</h1><p className='service-third-content'> Connect with entrepreneurs for collaboration.</p></div>
<div className='flex-items'> <img src={exhibition} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Exhibition Arrangements:</h1> 
<p className='service-third-content'>Attend key exhibitions with organized logistics.</p></div>
<div className='flex-items'> <img src={personal} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Personal Branding:</h1> 
<p className='service-third-content'>Building Strong Personal Brands for Success in India.</p></div>
<div className='flex-items'> <img src={china_india} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>China India Cross border Ecommerce association</h1> 
<p className='service-third-content'>Facilitating smooth and efficient ecommerce transactions and partnerships between Chinese and Indian businesses.</p></div>
</div></div>
<div className="china-india">
<div className='service-2-heading'>
<div> <img src={china} alt="cibb india" className='india-logo'></img>
<h1 className='service-3-heading'>For Indians from China</h1></div></div><div className='service-content-3'>
<div className='flex-items'> <img src={idea} alt="cibb connection" className='connection'/>
    <h1 className='service-third-heading'>
    Product Ideas & Sourcing: </h1>
<p className='service-third-content'> Match with verified buyers and companies.
Payment Guarantee: Secure transactions with trusted protections.</p></div>
<div className='flex-items'><img src={buying} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Group Buying: </h1> 
<p className='service-third-content'>Leverage collective purchasing for better deals.</p></div>
<div className='flex-items'><img src={office} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Company & Factory Setup: </h1><p className='service-third-content'> Easy business and factory establishment in India.</p>
</div>
<div className='flex-items'><img src={quality} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Quality Inspection:</h1> <p className='service-third-content'>Ensure high product standards with thorough inspections.
</p></div><div className='flex-items'><img src={logistic} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Logistics:</h1><p className='service-third-content'> Smooth and reliable transportation and delivery services.</p>
</div><div className='flex-items'><img src={network} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Entrepreneur Networking:</h1> 
<p className='service-third-content'>Build valuable connections within the business community.
</p></div><div className='flex-items'><img src={factory} alt="cibb connection" className='connection'/>
<h1 className='service-third-heading'>Exhibition & Factory Visits:</h1> 
<p className='service-third-content'> We organize exhibition trips and factory tours.
</p></div>
<div className='flex-items'> <img src={personal} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>Personal Branding:</h1> 
<p className='service-third-content'>Building Strong Personal Brands for Success in India.</p></div>
<div className='flex-items'> <img src={china_india} alt="cibb connection" className='connection'/><h1 className='service-third-heading'>China India Cross border Ecommerce association</h1> 
<p className='service-third-content'>Facilitating smooth and efficient ecommerce transactions and partnerships between Chinese and Indian businesses.</p></div>
  </div>
  </div>

  </div>
  

  );
};

export default Service;
