import React, { useState, useEffect, useRef } from 'react';
import './MouseFollower.css';
import mouse from './images/mouse-followe.jpeg';
const MouseFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const targetPosition = useRef({ x: 0, y: 0 }); // Target position for smooth transition
  const requestRef = useRef(null);

  // Update the target position when the mouse moves
  useEffect(() => {
    const handleMouseMove = (e) => {
      targetPosition.current = { x: e.clientX, y: e.clientY };
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Animate the movement smoothly
  useEffect(() => {
    const smoothMovement = () => {
      setPosition((prevPosition) => {
        const dx = targetPosition.current.x - prevPosition.x;
        const dy = targetPosition.current.y - prevPosition.y;

        // Interpolate to gradually approach the target position
        const newX = prevPosition.x + dx * 0.02;
        const newY = prevPosition.y + dy * 0.02;

        return { x: newX, y: newY };
      });

      // Continue animating
      requestRef.current = requestAnimationFrame(smoothMovement);
    };

    // Start the animation
    requestRef.current = requestAnimationFrame(smoothMovement);

    // Clean up the animation frame on component unmount
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return (
    // <img src={mouse}
    //   className="mouse-follower"
    //   style={{
    //     left: `${position.x}px`,
    //     top: `${position.y}px`,
    //   }}
    // />
    <div></div>
  );
};

export default MouseFollower;
