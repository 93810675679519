import React from 'react';
import './About.css';
import about_img from './images/home-bg-1.jpg';
import about_image from './images/bg img2.webp'
function About(){
    return(<div>
        <div className='about-header'>

        </div>
          <center><h1 className='about-header-content'>About Us</h1></center> 
      
       
          <div className='about-content-img service-2-heading'>
              <img src={about_img} className='about-img1'/>
             
        
          </div>
          <div className='about-content'>
           <center> <h2>At CIBB, we turn business challenges into opportunities. From product sourcing to factory setup, we make your growth our mission. With a team of experts and a global network, we simplify your business journey in India and China.

          </h2>
          <h3>Let’s build, grow, and innovate—together!</h3> </center>
       
          </div>


  
  </div>);

}
export default About;